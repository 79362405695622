* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  width: 100%;
  min-height: 100vh; /* Changed from height to min-height to allow for more content */
  padding: 70px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow-y: auto; /* Added to handle overflow */
}

input {
  margin-inline: 1rem;
  margin-block: 2rem;
  width: 700px;
  padding: 15px;
}

button {
  padding: 15px;
  margin: 0 auto;
}

.element {
  margin-block: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: start; /* Align items to the start to prevent overlap */
  justify-content: center;
  gap: 4rem; /* Adjusted gap to increase space between items */
  margin-top: 50px; /* Adjusted margin-top for better spacing */
}

.element img {
  max-width: 250px;
}

.element-div {
  min-height: 600px; /* Increased height to accommodate reviews */
  border: 3px solid black;
  margin: 0 auto;
  padding: 20px;
}

p {
  margin: 0 auto;
}

.container img {
  width: 700px; /* Change the width value to your desired size */
  height: auto; /* Keep the height auto to maintain aspect ratio */
}

.container {
  position: relative;
}

.container input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.container button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="radio"] {
  display: none;
  border: #ccc solid 1px;
  border-radius: 50%;
}

.star {
  cursor: pointer;
  transition: color 200ms;
}

.sticky-search {
  position: sticky;
  top: 0;
  background-color: white; /* Adjust this to match your design */
  z-index: 100; /* Ensure the search bar stays on top of other content */
  transition: height 0.3s ease;
}

.sticky-search.minimized {
  height: 50px; /* Adjust this value to minimize the search bar */
}

.content {
  padding-top: 100px; /* Adjust this to account for the height of the minimized search bar */
}

.review-box {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 20px 0; /* Increase the vertical margin for more space */
}

.review-text {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
